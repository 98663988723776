<template>
  <div style="margin-top: 50px">
    <el-form>
      <el-form-item label="旧密码：" label-width="500px">
        <el-input v-model="form.passwd" style="width: 300px" />
      </el-form-item>
      <el-form-item label="新密码：" label-width="500px">
        <el-input v-model="form.newPasswd" style="width: 300px" />
      </el-form-item>
      <el-form-item label="确认新密码：" label-width="500px">
        <el-input v-model="form.affirmPasswd" style="width: 300px" />
      </el-form-item>
      <el-form-item label-width="500px">
        <el-button type="success" @click="updataPassword">确认修改</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { updatePasswords } from '@/api/user.js';
export default {
  data() {
    return {
      form: {
        passwd: '',
        newPasswd: '',
        affirmPasswd: ''
      }
    };
  },
  methods: {
    updataPassword() {
      this.$confirm('确定要修改密码吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        updatePasswords(this.form)
          .then(res => {
            if (+res.data.code === 0) {
              this.$message({
                type: 'success',
                message: '修改成功'
              });
            } else {
              this.$message({
                type: 'error',
                message: res.data.msg
              });
            }
          });
      });
    }
  }
};
</script>

<style>

</style>
